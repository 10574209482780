/* eslint-disable import/first */

// This is to demonstrate a basic header/content/footer page structure.
// It's expected that real sites will build their own structure in a
// similar way, implementing their own `src/components/layout.js` or
// similar.

import React, { useState, Fragment } from 'react';

import Logotype from '../Logotype';

import Breadcrumbs from '../Breadcrumbs';

import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import PageFooter from '../PageFooter';

import { FooterWithNavBoxes } from '../Footer';

import { useTheme } from '../../muiTheming';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";


export const SamplePage = ({theme, breadcrumbs, wrapper, breadcrumbSeparator, children, ...rest}) => {

    // Ignore the passed theme
    theme = useTheme();

    const Wrap = ({children, ...props}) => (
        wrapper ? (
            <Paper style={{margin:theme.spacing(2)}} {...props}>{children}</Paper>
        ) : (
            <>{children}</>
        )
    );

    const Header = (props) => (
        <PageHeader {...props}>
            <Logotype />
        </PageHeader>
    );

    const Content = ({children, ...props}) => (
        <PageContent {...props}>
            <Grid container direction="column" style={{margin: theme.spacing(2)}}>
                <Grid item>{
                    breadcrumbs ? (
                        <Breadcrumbs entries={breadcrumbs} separator={breadcrumbSeparator} />
                    ) : null
                }</Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </PageContent>
    );

    const Footer = (props) => (
        <PageFooter {...props}>
            <FooterWithNavBoxes />
        </PageFooter>
    );

    return (
        <Wrap>
            <Header />
            <Content children={children} />
            <Footer />
        </Wrap>
    );
};

export default SamplePage;
