

/* eslint-disable import/first */

import React from 'react';

import Search from '@mui/icons-material/Search';

export const SearchIcon = Search;
export default SearchIcon;
