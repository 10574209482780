import {
    getUserobjects,
    getProperties,
    createUserobject,
    updateUserobject,
    deleteUserobject,
    updatePropertyPreference,
    unsubscribe,
} from "../api"
import md5 from "md5"
import { isEmpty } from "lodash"
import {
  doLogout
} from '../utils';

import { API_ERROR_MESSAGE } from "../constants";

const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          dispatch(
            actions.savedActions(
              'API_ERROR',
              {
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          dispatch(
            actions.savedActions(
               'SERVICE_ERROR',
              {
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }

    return({
        getUserobject: payload => {
            dispatch(actions.savedActions("DATA_LOADING", { loading: true }))
            getUserobjects(payload).then(res => {
                dispatch(actions.savedActions("GET_SAVED_PROPERTIES", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        getProperties: payload => {
            getProperties(payload).then(res => {
                dispatch(actions.savedActions("GET_PROPERTIES", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        createUserobject: payload => {
            // const data = { ...payload, type_id: md5(payload.params) };
            payload.type_id = md5(payload.params)
            createUserobject(payload).then(res => {
                dispatch(actions.savedActions("SAVE_PROPERTY", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        updateUserobject: payload => {
            updateUserobject(payload).then(res => {
                dispatch(actions.savedActions("UPDATE_SAVED_PROPERTY", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        deleteUserobject: payload => {
            deleteUserobject(payload).then(res => {
                dispatch(actions.savedActions("DELETE_SAVED_PROPERTY", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        updatePropertyPreference: payload => {
            updatePropertyPreference(payload).then(res => {
                dispatch(actions.savedActions("SAVE_PROPERTY", res.data))
            })
            .catch((error) => {
              handleError(error)
            })
        },
        isSaved: (userObjects, params, type) => {

            if (isEmpty(userObjects)) return false

            if (isEmpty(userObjects[type])) return false

            return !!userObjects[type].filter(
                x => JSON.stringify(x.params).toLowerCase() === JSON.stringify(params).toLowerCase() && x.state
            ).length
        },
        unsubscribe: payload => {
          let postData = {...payload}
          let trackData = postData?.trackData;

          if (postData?.trackData)
            delete postData.trackData

            unsubscribe(postData)
                .then(res => {
                    if (trackData && typeof trackData === 'function') {
                      trackData()
                    }
                    dispatch(actions.savedActions("UNSUBSCRIBE", res.data))
                })
                .catch((error) => {
                  handleError(error)
                })
        },
    })
}

export default services
